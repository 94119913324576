<template>
  <username-selector
      v-model="edurooms_username"
      :type.once="edurooms_username_type"
      title=""
      @type-changed="onUsernameTypeChanged"
  ></username-selector>
</template>

<script>
import UsernameSelector from "../AccountSettings/UsernameSelector.vue"

export default {
  name: 'sign-in-username-adapter',
  components: {
    UsernameSelector,
  },
  props: {
    containerId: {
      type: String,
      required: true
    },
    inputName: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      edurooms_username: null,
      edurooms_username_type: "email",  // Default
    }
  },
  watch: {
    edurooms_username: function (newVal) {
      this.setInputValue(newVal)
    },
  },
  methods: {
      setInputValue(value) {
        const inputSelector = `#${this.containerId} input[name="${this.inputName}"]`;
        const input = document.querySelector(inputSelector)
        input.value = value
      },
    onUsernameTypeChanged(type) {
      this.edurooms_username_type = type;
      this.$emit('type-changed', type);
    },
  },
}
</script>

<style scoped>

</style>
