
import Notify from "./Notify.vue";
import VerifyContact from "./VerifyContact.vue";

export default (createApp) => {
  return () => {
    const signInContainer = document.getElementById('verify_vue_container');
    if (!signInContainer) {
      return;
    }

    const app = createApp({
      components: {
        notify: Notify,
        'verify-contact': VerifyContact,
      },
      data() {
        return {}
      },
      mounted() {
        this.$clients.initializeClients();
      },
    });
    app.mount("#verify_vue_container")
    return app
  }
}
