import OtpForm from './OtpForm.vue';
import Notify from "./Notify.vue";
import SignIn from "./SignIn.vue";

export default (createApp) => {
  return () => {
    const signInContainer = document.getElementById('login_vue_container');
    if (!signInContainer) {
      console.warn("No login container found")
      return;
    }
    const app = createApp({
      data() {
        return {
          emailValue: '',
          passwordValue: '',
          passwordConfirmValue: '',
          showValidationMessage: false,
        };
      },
      components: {
        'sign-in': SignIn,
        'otp-form': OtpForm,
        notify: Notify,
      },
      mounted() {
        this.$clients.initializeClients();
        this.displayLoginForm();
      },
      methods: {
        displayLoginForm () {
          const loginLoading = document.getElementById('login-form-loading');
          const loginContainer = document.getElementById('login-form-container');

          if(loginLoading) {
            loginLoading.classList.add("display-none")
          }

          if(loginContainer) {
            loginContainer.classList.remove("display-none")
          }
        }
      },
    });
    app.mount('#login_vue_container')
  }
}
