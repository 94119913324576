/**
 * Adapter class that provides a standard interface for requesting OTP codes and verifying submitted codes.
 * This allows us to make changes to the login page's backend communication method without changing the OTP form itself
 */
export class OtpService {
    constructor(client = null) {
        this.client = client;
    }
    usingClient(client) {
        this.client = client;
        return this;
    }

    requestNewOtp(user_id, contact_method) {
        this.assertClient();
        return this.client.requestNewOtp(user_id, contact_method);
    }

    verify(user_id, contact_method, otp) {
        this.assertClient();
        return this.client.verify(user_id, contact_method, otp);
    }

    assertClient() {
        if (!this.client) {
            throw new Error("No client set");
        }
    }
}

/**
 * This client uses the legacy ERB / Turbo Login form to submit the OTP.
 */
export class LoginFormClient {
    constructor(formId) {
        if (!formId) {
            // Default form location.
            formId = document.getElementById('login-form-container').children[0].id
        }
        this.form = document.getElementById(formId);
    }

    requestNewOtp(userId, contactMethodName) {
        return Promise.reject(new Error("Not implemented"));
    }

    verify(userId, contactMethodName, otp) {
        this.form.elements['user[otp_attempt]'].value = otp;
        this.form.elements['user[contact_method]'].value = contactMethodName;
        if (typeof this.form.requestSubmit == "function") {
            this.form.requestSubmit();
        }
        else {
            // Safari doesnt have requestSubmit, so we use this polyfill
            const submitter = document.createElement("input");
            submitter.type = "submit";
            submitter.hidden = true;
            this.form.appendChild(submitter);
            submitter.click();
            this.form.removeChild(submitter);
        }
        // If the Plain HTML form doesnt return in 5000, then we know it failed.
        return new Promise((resolve, reject) => setTimeout(reject, 5000))
    }
}

/**
 * Standard async API client for OTP.
 */
export class OtpRestClient {
    constructor(client) {
        this.client = client;
    }
    requestNewOtp(user_id, contact_method) {
        const options = {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        const payload = { user_id, contact_method };
        return this.client.post("/api/v1/users/mfa_code", payload, options);
    }

    verify(userId, contactMethod, otp) {
        return Promise.reject(new Error("Not implemented"));
    }
}
