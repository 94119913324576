import { createApp } from 'vue'
import Vuelidate from '@vuelidate/core';
import Notifications from '@kyvg/vue3-notification'
import OtpInput from "vue3-otp-input";
import SignInUsernameAdapter from "../components/Guardianships/SignInUsernameAdapter.vue";
import SignIn from "../components/SignIn.vue";

import VerifyContactRoot from "../components/VerifyContactRoot";
import SignInRoot from "../components/SignInRoot";
import ANotifications from '../components/Notifications/Notification.vue';
import api from '../plugins/api';
import analytics from "../plugins/amplitude";

import { plugin as Base } from '@apptegy/base';
import { plugin as Containers } from '@apptegy/containers';
import { plugin as Forms } from '@apptegy/forms';
import { plugin as Navigation } from '@apptegy/navigation';
import { plugin as Icons } from '@apptegy/icons';
// import { plugin as Notification } from '@apptegy/notification';
import { plugin as Pagination } from '@apptegy/pagination';
import { plugin as Table } from '@apptegy/table';

import '@apptegy/styles';
import '@apptegy/base/dist/style.css';
import '@apptegy/containers/dist/style.css';
import '@apptegy/forms/dist/style.css';
import '@apptegy/navigation/dist/style.css';
import '@apptegy/pagination/dist/style.css';
import '@apptegy/table/dist/style.css';
import '@apptegy/table/dist/style.css';
import '@apptegy/notification/dist/style.css';

import { VueIntl } from '@apptegy/vue-intl';

import en from '../locale/en.json';
import es from '../locale/es.json';

import formsLocale from '@apptegy/forms/locale/en.json'
import notificationLocale from '@apptegy/notification/locale/en.json'
import baseLocale from '@apptegy/base/locale/en.json'
import containersLocale from '@apptegy/containers/locale/en.json';
import navigationLocale from '@apptegy/navigation/locale/en.json';

// import configureVueErrorHandler from "../utils/ConfigueVueErrorHandler";
import Appsignal from "../utils/Appsignal";

const createCustomApp = (AppComponent)=> {
  const app = createApp(AppComponent)
  app.use(Base);
  app.use(Containers);
  app.use(Forms);
  app.use(Navigation);
  app.use(Icons);
  app.component('ANotifications',ANotifications);
  app.use(Pagination);
  app.use(Table);
  app.use(api);
  app.use(analytics);
  app.use(Vuelidate);
  app.use(Notifications, {componentName: 'kyvg-notification'})
  app.use(VueIntl, {
    defaultLocale: 'en',
    locales: [
      {
        code: 'en',
        messages: {
          ...en,
          dscl: { ...notificationLocale, ...baseLocale, ...formsLocale, ...containersLocale, ...navigationLocale },
        },
      },
      {
        code: 'es',
        messages: es
      },
    ],
  });
  app.component('v-otp-input', OtpInput);
  app.component('sign-in-username-adapter', SignInUsernameAdapter);
  app.component('sign-in', SignIn);
  return app;
}
/**
 * Legacy entrypoint for application
 * @todo: Move remaining Vue code from ERB into .vue only files. Then eliminate this section.
 */
document.addEventListener('DOMContentLoaded', () => {
  const APPSIGNAL_ERROR_MONITORING_API_KEY = document.getElementById("appsignal-key")?.value || null;
  Appsignal(APPSIGNAL_ERROR_MONITORING_API_KEY);
  // TODO: FIx this befote deploy
  // configureVueErrorHandler()
  SignInRoot(createCustomApp)()
  VerifyContactRoot(createCustomApp)()
})
