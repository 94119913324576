<template>
  <div>
    <div class="container" v-show="!isBusy || !busyMessage">
      <h1>{{ $t('otp.enterCode') }}</h1>
      <p>{{ $t(`otp.codeDetail.${ selectedContactMethod.name }`, { masked: selectedContactMethod.masked }) }}</p>
      <p v-if="expires">{{ $t('otp.enterCodeExpires', { expires }) }}</p>
      <div class="otp-container" v-bind:class="{ 'disabled': isBusy }">
        <v-otp-input
            ref="otpInput"
            input-type="number"
            input-classes="otp-input"
            separator=""
            :num-inputs="6"
            :should-auto-focus="true"
            @on-complete="handleOnComplete"
        />
      </div>
      <p class="otp-error" v-if="errorMessage">{{ errorMessage }}</p>
      <p class="resend">
        {{ $t('otp.didNotReceive') }} <a class="purple-link" @click="requestNewOtp(selectedContactMethod)">{{ $t('otp.resendCode') }}</a>
        <small class="resend-using-other-method" v-if="otherContactMethods.length > 0">
          {{ $t('otp.orSendUsing') }}
          <a v-for="method in otherContactMethods" class="purple-link" @click="requestNewOtp(method)">
            {{ $t(`contactMethods.${method.name}`) }}
          </a>
        </small>
      </p>
    </div>
    <div class="busy-container" v-show="isBusy && busyMessage">
      <h1>{{ busyMessage }}</h1>
    </div>
  </div>
</template>

<script>
import { LoginFormClient, OtpRestClient, OtpService } from "../services/OtpService";

export default {
  name: "OtpForm",

  beforeMount() {
    this.otpService = new OtpService();
    this.primaryContactMethods = JSON.parse(this.contacts);
    this.selectedContactMethod = this.preferredContactMethod;
  },
  data() {
    return {
      isBusy: false,
      primaryContactMethods: [],
      selectedContactMethod: null,
      busyMessage: null,
      errorMessage: this.message && this.message.trim() !== "" ? this.message : null,
    }
  },
  props: {
    expires: {  // This is only for display purposes. It's not used in the code.
      type: String,
      required: false,
      default: null
    },
    user: {
      type: String,
      required: true,
    },
    contacts: {
      type: String,
      required: true,
    },
    // Since we are still submitting via HTML form, if a user sumbits an invalid OTP value,
    // the form is submitted and page is refreshed with an error message. This is how we expose that message
    // until we can submit via REST and handle errors in the component internally.
    message: {
      type: String,
      required: false,
    },
  },
  computed: {
    preferredContactMethod() {
      const preferredMethod = this.primaryContactMethods.find(m => m.preferred === true);
      return preferredMethod ? preferredMethod : this.primaryContactMethods[0];
    },
    otherContactMethods() {
      return this.primaryContactMethods.filter(m => m.name !== this.selectedContactMethod.name);
    },
  },
  methods: {
    setBusy(message) {
      this.isBusy = true;
      this.busyMessage = message;
      this.errorMessage = null;
    },
    clearBusy() {
      this.isBusy = false;
      this.busyMessage = null;
    },
    handleOnComplete(value) {
      this.setBusy(this.$t('otp.verifying'));
      this.otpService
          .usingClient(new LoginFormClient())
          .verify(this.user, this.selectedContactMethod.name, value )
          .catch((error) => {
            console.error(error)
            this.$notify.error(this.$t('otp.invalidCode'));
            this.$refs.otpInput.clearInput();
          }).finally(() => {
            this.clearBusy();
          });
    },
    setSelectedContactMethod(contactMethod) {
      this.selectedContactMethod = contactMethod;
    },
    requestNewOtp(contactMethod) {
      this.setBusy();
      this.setSelectedContactMethod(contactMethod);
      this.otpService
          .usingClient(new OtpRestClient(this.$clients.oauth))
          .requestNewOtp(this.user, this.selectedContactMethod.name)
          .then((response) => {
            this.$notify({
              group: "default",
              type: 'info',
              duration: 2000,
              title: this.$t('otp.newCodeSent.' + this.selectedContactMethod.name, { masked: this.selectedContactMethod.masked }),
            });
          })
          .catch((error) => {
            this.$notify({
              type: 'error',
              duration: 2000,
              title: this.$t('otp.errorSendingCode.' + this.selectedContactMethod.name, { masked: this.selectedContactMethod.masked }),
              text: this.$t('otp.codeDetail.' + this.selectedContactMethod.name, { masked: this.selectedContactMethod.masked }) + ' ' +
                    this.$t('otp.enterCodeExpires', { expires: this.expires }),
            });
          }).finally(() => {
            this.clearBusy();
            this.$refs.otpInput.clearInput();
          });
    },
  }
}
</script>

<style lang="scss">
  .disabled {
    transition: all 0.1s ease-out;
    opacity: 0.3;
  }
  .resend {
    margin-top: 16px;
    margin-bottom: 16px;
    a {
      cursor: pointer;
    }
  }
  .otp-error {
    margin-top: 16px;
    margin-bottom: 16px;
    color: red;
  }
  .otp-container {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .otp-input {
    width: 40px;
    height: 40px;
    padding: 5px;
    margin: 0 5px;
    font-size: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
    &.error {
      border: 1px solid red !important;
    }
  }
  .otp-input::-webkit-inner-spin-button,
  .otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number].otp-input {
    -moz-appearance: textfield;
  }
</style>