/**
 * Standard async API client for Contact Method Verification OTP.
 */
export class ContactMethodOtpClient {
  constructor(client) {
    this.client = client;
    this.options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
  }
  requestNewOtp(user_id, contact_method) {
    return this.client.post(
      "/contact_method/send_verification_code",
      { id: user_id, contact_method },
      this.options
    );
  }

  // Unnecessary for now, but will be needed if we ever implement verify
  // as an async call
  verify(user_id, contact_method, otp) {
    throw "Not implemented"
  }
}
